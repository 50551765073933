<template>
  <div id="app">
    <!-- <h1>Прототипирование сайтов💡</h1>
    <p>
      При проектировании интерфейсов главное правило – не изобретать велосипед!
      Принципы юзабилити были сформированы много лет назад при популяризации
      интернета. Соблюдая эти стандарты мы уверенны, что ваш сайт будет удобен
      для каждого посетителя.
    </p>
    <button class="btn btn-max mt-34">Узнать стоимость прототипа</button>
    <button class="btn btn-sm mt-24">Заказать</button>
    <Socials class="mt-34" /> -->
    <router-view />
  </div>
</template>

<script>
import Socials from "@/components/SmallComponents/Socials.vue";
export default {
  components: {
    Socials,
  },
};
</script>
