<template>
  <header class="header">
    <div class="container d-flex align-center justify-between">
      <div class="header__left d-flex align-center gap-24">
        <a href="#!" class="header__logo">
          <img src="/images/logo.svg" />
        </a>
        <span class="header__line"></span>
        <div class="header__slogan"><span>Студия экспресс дизайна</span></div>
      </div>
      <div class="header__right">
        <Socials />
      </div>
    </div>
  </header>
</template>
<script>
import Socials from "./SmallComponents/Socials.vue";
export default {
  components: {
    Socials,
  },
};
</script>
<style scoped>
.header__line {
  width: 1px;
  height: 34px;
  background: var(--gray-transparent);
}
</style>
