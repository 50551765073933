<template>
  <div class="socials d-flex align-center gap-24">
    <a
      :href="item.link"
      class="socials__item d-flex align-center gap-14"
      v-for="item of socials"
      :key="item.id"
      target="_blank"
    >
      <div class="socials__item-icon d-grid place-center">
        <img :src="item.icon" />
      </div>
      <span class="socials__item-text">{{ item.name }}</span>
    </a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      socials: [
        {
          icon: "/images/icons/telegram.svg",
          name: "В Telegram",
          link: "https://yourlink.com",
        },
        {
          icon: "/images/icons/whatsapp.svg",
          name: "В WhatsApp",
          link: "https://yourlink.com",
        },
      ],
    };
  },
};
</script>

<style scoped>
.socials__item {
  height: 62px;
  padding: 0 24px;
  border: 1px solid var(--gray-transparent);
  border-radius: 999px;
}
.socials__item-icon {
  width: 36px;
  height: 36px;
  background: var(--gray);
  border-radius: 50%;
}
.socials__item-text {
  margin-top: 5px;
}
</style>
