<template>
  <div class="home">
    <Header class="pt-24" />
    <Hero />
    <Steps />
    <Example />
    <Header class="pb-24" />
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import Hero from "@/components/HomeComponents/Hero.vue";
import Steps from "@/components/HomeComponents/Steps.vue";
import Example from "@/components/HomeComponents/Example.vue";

export default {
  components: {
    Header,
    Hero,
    Steps,
    Example,
  },
};
</script>
